var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"padding-a-3 h-full"},[_c('div',{staticClass:"d-flex flex-column justify-space-between h-full"},[_c('div',[_c('div',{staticClass:"position-relative",style:({
          width: _vm.isSoldOut ? 'min-content' : '100%',
          minHeight: '20px',
          zIndex: 1,
        }),on:{"mouseover":_vm.handleMouseOver,"mouseleave":_vm.handleMouseLeave}},[_c('button',{staticClass:"d-flex justify-center align-center border-1 border-solid border-radius-20 w-100",class:{
            'background-primary-orange-3 border-primary-orange': _vm.isSoldOut,
            'background-primary-gray-border-dark border-primary-gray-border-dark':
              !_vm.isSoldOut,
          },style:({
            opacity:
              _vm.iconType && (_vm.iconType !== _vm.SoldOutIcons.plus || !_vm.iconTimeout)
                ? '100%'
                : '0%',
            transition: 'opacity 0.1s linear',
          }),on:{"click":_vm.setSoldOut}},[_c('span',{staticClass:"d-flex align-center justify-center"},[_c('p',{staticClass:"margin-l-1 overflow-hidden white-space-nowrap font-12 leading-6 max-w-162 font-semibold",class:{
                'text-primary-orange-2': _vm.isSoldOut,
                'text-gray-border-dark': !_vm.isSoldOut,
              },style:({
                lineHeight: '18px',
              })},[_vm._v(" Sold Out ")]),(_vm.iconType)?_c('CUIcon',{key:_vm.iconType,staticClass:"margin-l-1",attrs:{"color":_vm.iconType === _vm.SoldOutIcons.plus ? 'gray-border-dark' : 'orange',"width":"12px","height":"12px"}},[_vm._v(" "+_vm._s(_vm.iconType)+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"min-h-20 cursor-pointer",attrs:{"id":"reservationContext"},on:{"click":_vm.handleClickDate}},[(_vm.holiday)?_c('div',{staticClass:"h-20 border-radius-20 d-flex align-center justify-center border-1 border-solid border-additional-blue",style:({
            maxWidth: '100%',
            marginTop: _vm.availability ? '3px' : '22px',
          })},[_c('span',{staticClass:"d-flex align-center justify-center"},[_c('CUIcon',{attrs:{"width":"12px","color":"additional-blue"}},[_vm._v(" CalendarCheck ")]),_c('p',{staticClass:"text-additional-blue margin-l-1 text-overflow-ellipsis overflow-hidden white-space-nowrap font-12 font-semibold max-w-140"},[_vm._v(" "+_vm._s(_vm.holiday.name)+" ")])],1)]):_vm._e()])]),(_vm.availability.utilizedVehiclePercentage !== undefined)?_c('div',{staticClass:"d-flex flex-column justify-end flex-grow-1 cursor-pointer",on:{"click":_vm.handleClickDate}},[_c('div',[_c('div',{staticClass:"d-flex align-center justify-start"},[_c('div',{staticClass:"d-flex align-center justify-start"},[_c('CUIcon',{staticClass:"text-gray-text-dark w-18",attrs:{"height":"18px"}},[_vm._v(" Bus ")])],1),_c('span',{staticClass:"text-gray-text-dark font-12 margin-t-1 margin-l-2 leading-6 font-bold",style:({
              fontFamily: 'Nunito, sans-serif',
            })},[_vm._v(" "+_vm._s(_vm.availability.utilizedVehiclePercentage)+"% ")])]),_c('v-progress-linear',{staticClass:"border-radius-5",attrs:{"value":_vm.availability.utilizedVehiclePercentage,"buffer-value":"100","background-color":"grey","background-opacity":"0.2","color":"secondary","width":"100%"}})],1),_c('div',[_c('div',{staticClass:"d-flex align-center justify-start padding-t-1"},[_c('CUIcon',{staticClass:"text-gray-text-dark w-18",attrs:{"height":"18px"}},[_vm._v(" Driver ")]),_c('span',{staticClass:"text-gray-text-dark font-12 margin-t-1 margin-l-2 leading-6 font-bold",style:({
              fontFamily: 'Nunito, sans-serif',
            })},[_vm._v(" "+_vm._s(_vm.availability.utilizedDriverPercentage)+"% ")])],1),_c('v-progress-linear',{staticClass:"border-radius-5 margin-b-0",attrs:{"buffer-value":"100","background-color":"grey","background-opacity":"0.2","color":"secondary","value":_vm.availability.utilizedDriverPercentage}})],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }