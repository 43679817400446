
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {
  TimelineScaleOptionDays,
  TimelineScaleOptions,
  TimelineScaleOptionsValues,
} from '@/models/dto/AssignmentsGrid'
import { Dayjs } from 'dayjs'

@Component
export default class CUTimelineCalendarTimeline extends Vue {
  @Prop({ required: true }) scale: string
  @Prop({ required: true }) startDate: Dayjs
  @Prop({ required: true }) selected: { start: number; end: number }
  @Prop({ required: false, default: null }) selectedStartDate: Dayjs

  HOURS_IN_DAY = 24
  bufferHours = 4

  calculateHours(): number[] {
    const diff = this.selectedStartDate.diff(this.startDate, 'hours')
    let startingHour = this.HOURS_IN_DAY + this.bufferHours - diff
    if (startingHour <= 0) {
      startingHour = this.HOURS_IN_DAY + startingHour
    }

    const hours = [...Array(startingHour).keys()].reverse()
    for (let i = 1; i <= diff; i++) {
      hours.push(i % this.HOURS_IN_DAY)
    }

    const index = hours.indexOf(this.HOURS_IN_DAY)
    const before = hours
      .slice(0, index + 1)
      .map((hour) =>
        hour >= this.HOURS_IN_DAY
          ? this.HOURS_IN_DAY - (hour % this.HOURS_IN_DAY)
          : hour
      )

    const zeroIndex = hours.indexOf(0)
    const after = hours
      .slice(index + 1, hours.length)
      .slice(0, this.HOURS_IN_DAY - before.length)
      .map((hour, i) =>
        zeroIndex !== -1 && i > zeroIndex ? hour : this.HOURS_IN_DAY - hour
      )

    return [...before, ...after]
  }

  calculateWidth(index: number, width: number): number {
    return width
  }

  get showSelected(): boolean {
    return (
      !!(this.selected?.start || this.selected?.start === 0) &&
      !!this.selected?.end
    )
  }

  get is12Hours(): boolean {
    return this.scale === TimelineScaleOptions.HALFDAY
  }

  get is24Hours(): boolean {
    return this.scale === TimelineScaleOptions.DAY
  }

  get is3days(): boolean {
    return this.scale === TimelineScaleOptions.HALFWEEK
  }

  get is7days(): boolean {
    return this.scale === TimelineScaleOptions.WEEK
  }

  get options(): { repeat: number; width: number } {
    switch (this.scale) {
      case TimelineScaleOptions.HALFDAY:
        return {
          repeat: TimelineScaleOptionDays.HALFDAY,
          width: TimelineScaleOptionsValues.HALFDAY,
        }
      case TimelineScaleOptions.DAY:
        return {
          repeat: TimelineScaleOptionDays.DAY,
          width: TimelineScaleOptionsValues.DAY,
        }
      case TimelineScaleOptions.HALFWEEK:
        return {
          repeat: TimelineScaleOptionDays.HALFWEEK,
          width: TimelineScaleOptionsValues.HALFWEEK,
        }
      case TimelineScaleOptions.WEEK:
        return {
          repeat: TimelineScaleOptionDays.WEEK,
          width: TimelineScaleOptionsValues.WEEK,
        }
      default:
        return {
          repeat: TimelineScaleOptionDays.DAY,
          width: TimelineScaleOptionsValues.DAY,
        }
    }
  }

  get bufferTimeWidth(): number {
    switch (this.scale) {
      case TimelineScaleOptions.HALFDAY:
        return (
          (TimelineScaleOptionsValues.HALFDAY / this.HOURS_IN_DAY / 60) * 60
        )
      case TimelineScaleOptions.DAY:
        return (TimelineScaleOptionsValues.DAY / this.HOURS_IN_DAY / 60) * 60
      case TimelineScaleOptions.HALFWEEK:
        return (
          (TimelineScaleOptionsValues.HALFWEEK / this.HOURS_IN_DAY / 60) * 60
        )
      case TimelineScaleOptions.WEEK:
        return (TimelineScaleOptionsValues.WEEK / this.HOURS_IN_DAY / 60) * 60
      default:
        return 3
    }
  }

  getDateString(index: number): string {
    if (!this.startDate) {
      return ''
    }

    if (this.is12Hours) {
      const isBefore12pm = this.selectedStartDate.hour() < 12
      return isBefore12pm
        ? this.selectedStartDate.add(index, 'days').format('ddd, MM/DD')
        : this.selectedStartDate.add(++index, 'days').format('ddd, MM/DD')
    }

    return this.selectedStartDate.add(index, 'days').format('ddd, MM/DD')
  }

  setBufferHours(): void {
    switch (this.scale) {
      case TimelineScaleOptions.HALFDAY:
        this.bufferHours = Math.floor(12 * 0.2)
        break
      case TimelineScaleOptions.DAY:
        this.bufferHours = Math.floor(24 * 0.2)
        break
      case TimelineScaleOptions.HALFWEEK:
        this.bufferHours = Math.floor(24 * 3 * 0.2)
        break
      case TimelineScaleOptions.WEEK:
        this.bufferHours = 4
        break
      default:
        this.bufferHours = 4
    }
  }

  @Watch('scale', { immediate: true })
  onScaleChange(): void {
    this.setBufferHours()
  }

  created(): void {
    this.setBufferHours()
  }
}
