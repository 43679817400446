
import { Vue, Component, Prop } from 'vue-property-decorator'
import AvailabilitySidebarHeader from '@/components/AvailabilitySidebarHeader.vue'
import AvailabilitySidebarLegend from '@/components/AvailabilitySidebarLegend.vue'
import AvailabilityReservationCard from '@/components/AvailabilityReservationCard.vue'
import AvailabilityCalendarRow from '@/components/AvailabilityCalendarRow.vue'
import CUTimelineCalendar from '@/components/CUTimelineCalendar.vue'
import dayjs from 'dayjs'
import {
  formatAvailabilityPanelData,
  getAvailabilityPanelData,
  getBlockColor,
  getEndDateFromStartDateAndScale,
  getStartAndEndOffsetFromDates,
} from '@/utils/availability'
import { VehicleType } from '@/models/dto'
import {
  AvailabilityBlock,
  AvailabilityPanelGetResponse,
} from '@/models/dto/Availability'
import {
  TimelineScaleOptions,
  TimelineScaleOptionsType,
} from '@/models/dto/AssignmentsGrid'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'

@Component({
  components: {
    AvailabilitySidebarHeader,
    AvailabilitySidebarLegend,
    AvailabilityReservationCard,
    AvailabilityCalendarRow,
    CUTimelineCalendar,
  },
})
export default class AvailabilitySidebar extends Vue {
  @Prop({ required: true }) date: string

  loading = false
  data: AvailabilityPanelGetResponse = null
  fleet: Record<string, number> = null
  vehicleTypes: VehicleType[] = []
  calendarData: AvailabilityBlock[] = []
  scale = TimelineScaleOptions.DAY
  startDate = dayjs().startOf('day')
  endDate = dayjs().endOf('day')
  selectStartDate = null
  selectEndDate = null
  legendKeys = [
    'Confirmed Assignment',
    'Draft Assignment',
    'Unassigned',
    'Buffer Time',
  ]
  tz = auth.getCompanyTimeZone

  getBlockColor = getBlockColor

  get totalAvailableVehicles(): number {
    if (!this.calendarData || !this.fleet) {
      return null
    }
    const totalVehicles = Object.keys(this.fleet).reduce(
      (sum, key) => sum + (key !== 'unassigned' ? this.fleet[key] : 0),
      0
    )
    return this.calendarData.reduce((sum, block) => {
      return (
        sum -
        (!block.reservationId
          ? block.totalVehicles - block.availableVehicles
          : 0)
      )
    }, totalVehicles)
  }

  get startEndOffsetFromSelectedDatesForBlock(): {
    start: number
    end: number
  } {
    if (!this.selectStartDate || !this.startDate) {
      return null
    }

    const [start, end] = getStartAndEndOffsetFromDates(
      this.startDate,
      this.scale,
      this.selectStartDate,
      this.selectEndDate,
      true
    )

    return {
      start,
      end,
    }
  }

  get startEndOffsetFromSelectedDates(): { start: number; end: number } {
    if (!this.selectStartDate || !this.startDate) {
      return null
    }

    const [start, end] = getStartAndEndOffsetFromDates(
      this.startDate,
      this.scale,
      this.selectStartDate,
      this.selectEndDate
    )

    return {
      start,
      end,
    }
  }

  get maxHoursThatFit(): number {
    switch (this.scale) {
      case TimelineScaleOptions.HALFDAY:
        return 12
      case TimelineScaleOptions.DAY:
        return 24
      case TimelineScaleOptions.HALFWEEK:
        return 24 * 3
      case TimelineScaleOptions.WEEK:
        return 24 * 7
    }
    return 0
  }

  async handleUpdateScale(scale: TimelineScaleOptionsType): Promise<void> {
    this.scale = scale
    await this.load()
    EventBus.$emit('reset-scroll', { offset: -9999, id: null })
  }

  handleUpdateStartDate(date: string): void {
    const startDate = dayjs(date).tz(this.tz, true)
    const endDate = dayjs(this.selectEndDate || this.endDate).tz(this.tz, true)

    if (startDate.isSame(this.startDate)) {
      return
    } else if (startDate.isAfter(endDate)) {
      this.startDate = startDate.startOf('day')
      this.endDate = startDate.endOf('day')
      this.selectStartDate = startDate
      this.selectEndDate = startDate.endOf('day')
    } else if (startDate.isBefore(this.startDate)) {
      this.startDate = startDate.startOf('day')
      this.selectStartDate = startDate
      this.selectEndDate = endDate
    } else {
      this.selectStartDate = startDate
      this.selectEndDate = endDate
    }

    this.updateScaleAndLoad()
  }

  handleUpdateEndDate(date: string): void {
    const endDate = dayjs(date).tz(this.tz, true)
    const startDate = dayjs(this.selectStartDate || this.startDate).tz(
      this.tz,
      true
    )

    if (endDate.isSame(this.endDate)) {
      return
    } else if (endDate.isBefore(startDate)) {
      this.startDate = endDate.startOf('day')
      this.endDate = endDate.endOf('day')
      this.selectStartDate = endDate.startOf('day')
      this.selectEndDate = endDate
    } else if (endDate.isAfter(this.endDate)) {
      this.endDate = endDate.endOf('day')
      this.selectStartDate = startDate
      this.selectEndDate = endDate
    } else {
      this.selectStartDate = startDate
      this.selectEndDate = endDate
    }

    this.updateScaleAndLoad()
  }

  handleUpdateStartTime(date: string): void {
    const startDate = dayjs(date).tz(this.tz, true)
    const endDate = dayjs(this.selectEndDate || this.endDate).tz(this.tz, true)

    if (this.selectEndDate && startDate.isAfter(this.selectEndDate)) {
      this.selectEndDate = this.endDate
    }
    this.selectStartDate = startDate
    this.selectEndDate = endDate
    this.updateScaleAndLoad()
  }

  handleUpdateEndTime(date: string): void {
    const endDate = dayjs(date).tz(this.tz, true)
    const startDate = dayjs(this.selectStartDate || this.startDate).tz(
      this.tz,
      true
    )

    if (this.selectStartDate && endDate.isBefore(this.selectStartDate)) {
      this.selectStartDate = this.startDate
    }
    this.selectEndDate = endDate
    this.selectStartDate = startDate
    this.updateScaleAndLoad()
  }

  updateScaleAndLoad(): void {
    const diffInHours = Math.abs(
      dayjs(this.selectStartDate || this.startDate).diff(
        this.selectEndDate || this.endDate,
        'hours'
      )
    )

    if (diffInHours > 24 * 3) {
      this.handleUpdateScale(TimelineScaleOptions.WEEK)
    } else if (diffInHours > 24) {
      this.handleUpdateScale(TimelineScaleOptions.HALFWEEK)
    } else if (diffInHours > 11) {
      this.handleUpdateScale(TimelineScaleOptions.DAY)
    } else {
      this.handleUpdateScale(TimelineScaleOptions.HALFDAY)
    }
  }

  async handleResetFilters(): Promise<void> {
    this.startDate = dayjs(this.date).startOf('day').tz(this.tz, true)
    this.endDate = dayjs(this.date).endOf('day').tz(this.tz, true)
    this.selectStartDate = this.startDate
    this.selectEndDate = this.endDate
    this.scale = TimelineScaleOptions.DAY

    await this.load()
    EventBus.$emit('reset-scroll', { offset: -9999, id: null })
  }

  handleCollapse(vehicleType: VehicleType): void {
    this.calendarData = this.calendarData.map((item) => ({
      ...item,
      collapsed:
        item.vehicleType.id === vehicleType.id
          ? !item.collapsed
          : item.collapsed,
    }))
  }

  async load(): Promise<void> {
    this.loading = true

    try {
      const endDate = getEndDateFromStartDateAndScale(
        this.selectStartDate || this.startDate,
        this.scale
      )
      const res = await getAvailabilityPanelData(
        // include reservations whose buffer time overlaps the date range
        (this.selectStartDate || this.startDate)
          .subtract(1, 'hour')
          .toISOString(),
        endDate.toISOString()
      )
      this.data = res.response
      this.vehicleTypes = res.vehicleTypes
      this.fleet = res.fleet

      this.calendarData = formatAvailabilityPanelData(
        this.startDate.toISOString(),
        this.endDate.toISOString(),
        this.selectStartDate.toISOString(),
        this.selectEndDate.toISOString(),
        this.scale,
        this.data,
        this.vehicleTypes,
        this.calendarData
      )

      this.calendarData.sort((a, b) => a.vehicleType.id - b.vehicleType.id)
    } catch (err) {
      EventBus.$emit('snackbar:error', 'Failed to load availability data.')
    } finally {
      this.loading = false
    }
  }

  created(): void {
    this.startDate = dayjs(this.date).startOf('day').tz(this.tz, true)
    this.endDate = dayjs(this.date).endOf('day').tz(this.tz, true)
    this.selectStartDate = this.startDate
    this.selectEndDate = this.endDate

    this.load()
  }
}
