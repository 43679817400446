
import {
  AvailabilityResponse,
  SoldOut,
  Holiday,
  SoldOutIconsType,
  SoldOutIcons,
} from '@/models/dto/Availability'
import { Component, Prop, Vue } from 'vue-property-decorator'
import availabilityClient from '@/services/availability'
import sidebar from '@/store/modules/sidebar'
import AvailabilitySidebar from './AvailabilitySidebar.vue'
import dayjs from 'dayjs'
import app from '@/store/modules/app'

@Component({})
export default class AvailabilityCalendarItem extends Vue {
  @Prop({ required: false }) availability: AvailabilityResponse
  @Prop({ required: false }) holiday: Holiday
  isHovered = false
  SoldOutIcons = SoldOutIcons
  soldOutComputed = false
  iconTimeout = false

  handleMouseOver(): void {
    this.isHovered = true
  }

  handleMouseLeave(): void {
    this.isHovered = false

    this.iconTimeout = true
    setTimeout(() => {
      this.iconTimeout = false
    }, 100) // match with transition time
  }

  handleClickDate(): void {
    sidebar.push({
      component: AvailabilitySidebar,
      width: 1400,
      props: {
        date: dayjs(this.availability.shortDate).format('YYYY-MM-DD'),
      },
      persist: true,
    })
  }

  async setSoldOut(): Promise<void> {
    try {
      if (this.availability.soldOut === undefined) {
        this.availability.soldOut = false
      }
      this.availability.soldOut = !this.availability.soldOut
      this.soldOutComputed = this.availability.soldOut
      this.isHovered = !this.isHovered

      const data: SoldOut = {
        date: this.availability.shortDate,
        soldOut: this.availability.soldOut,
      }

      await availabilityClient.setSoldOut(data)
    } catch (error) {
      console.error(error)
    }
  }

  get iconType(): SoldOutIconsType | undefined {
    if (this.availability?.soldOut && !this.isHovered) {
      return SoldOutIcons.confirm
    } else if (this.availability?.soldOut && this.isHovered) {
      return SoldOutIcons.minus
    } else if (this.isHovered || this.iconTimeout) {
      return SoldOutIcons.plus
    }

    return undefined
  }

  get isSoldOut(): boolean {
    return this.soldOutComputed
  }

  updated(): void {
    this.soldOutComputed = this.availability?.soldOut || false
  }

  mounted(): void {
    this.soldOutComputed = this.availability?.soldOut || false
  }
}
