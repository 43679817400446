var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-space-between margin-t-7 margin-b-5"},[_c('div',{staticClass:"d-flex align-center flex-wrap margin-x-2",style:({ gap: '24px' })},[_c('div',{staticClass:"d-flex align-center",style:({ gap: '8px' })},[_c('div',{staticClass:"d-flex align-center"},[_c('CUIcon',{staticClass:"margin-r-1 flex-shrink-0",attrs:{"color":_vm.isSelectMode ? 'primary' : 'additional-blue',"aria-label":"Start","width":"17px"}},[_vm._v(" flag_empty ")]),_c('p',{staticClass:"font-16 font-bold",class:{
            'text-primary': _vm.isSelectMode,
            'text-additional-blue': !_vm.isSelectMode,
          }},[_vm._v(" Start ")])],1),_c('CUDatePicker2',{attrs:{"id":"availability-start-date","value":_vm.startDate,"hide-details":"","hide-footer-inputs":"","predefined-date-keys":['today', 'tomorrow'],"width":"120px","label":_vm.startDate && 'Start Date',"placeholder":"Start Date"},on:{"input":function($event){return _vm.handleChangeStartDate($event)}}}),_c('CUTimePicker',{staticClass:"w-132",attrs:{"hide-details":"","value":_vm.startTime,"label":"Start Time"},on:{"input":function($event){return _vm.handleChangeStartTime($event)}}})],1),_c('div',{staticClass:"d-flex align-center",style:({ gap: '8px' })},[_c('div',{staticClass:"d-flex align-center"},[_c('CUIcon',{staticClass:"margin-r-1 flex-shrink-0",attrs:{"color":_vm.isSelectMode ? 'primary' : 'additional-blue',"aria-label":"End","width":"22px"}},[_vm._v(" flag_opaque ")]),_c('p',{staticClass:"font-16 font-bold",class:{
            'text-primary': _vm.isSelectMode,
            'text-additional-blue': !_vm.isSelectMode,
          }},[_vm._v(" End ")])],1),_c('CUDatePicker2',{attrs:{"id":"availability-end-date","value":_vm.endDate,"hide-details":"","hide-footer-inputs":"","predefined-date-keys":['today', 'tomorrow'],"width":"120px","label":_vm.endDate && 'End Date',"placeholder":"End Date"},on:{"input":function($event){return _vm.handleChangeEndDate($event)}}}),_c('CUTimePicker',{staticClass:"w-132",attrs:{"hide-details":"","value":_vm.endTime,"label":"End Time"},on:{"input":function($event){return _vm.handleChangeEndTime($event)}}})],1),_c('CUSelect',{staticClass:"w-124",attrs:{"id":"availability-scale","value":_vm.scale,"hide-details":"","label":_vm.scale && 'Scale',"items":_vm.computedScaleOptions,"placeholder":"Scale"},on:{"input":_vm.handleScaleChange}}),_c('CUTextField',{staticClass:"max-w-124",attrs:{"id":"availability-passengers","value":_vm.passengers,"hide-details":"","label":_vm.passengers && 'Passengers',"placeholder":"Passengers"},on:{"input":function($event){_vm.passengers = $event}}}),_c('CUButton',{key:"availability-reset-filters",staticClass:"padding-x-6",style:({ marginTop: '1px' }),attrs:{"disabled":!_vm.filtersHaveChanged,"outlined":"","small":"","unset-width":"","height":40},on:{"click":function($event){return _vm.$emit('reset')}},scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [_c('CUIcon',{key:"availability-reset-filters-icon",attrs:{"aria-label":"Reset","color":"gray-bg-2","width":20}},[_vm._v(" restart ")])]},proxy:true}])},[_vm._v(" Reset Filters ")])],1),_c('v-btn',{staticClass:"margin-t-1",attrs:{"icon":"","x-small":""}},[_c('CUIcon',{attrs:{"color":"bg-gray-6"},on:{"click":function($event){return _vm.sidebar.close()}}},[_vm._v("close")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }