var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.reservation.reservationId)?_c('div',{staticClass:"background-additional-blue-2"},[_c('div',{staticClass:"h-32 background-additional-blue-2"},[_c('div',{staticClass:"h-full d-flex justify-space-between align-center padding-x-2 cursor-pointer border-y-0 border-x-1 border-solid border-gray-border-light",on:{"click":function($event){return _vm.$emit('collapse', _vm.reservation.vehicleType)}}},[_c('p',{staticClass:"font-bold text-additional-blue"},[_vm._v(" "+_vm._s(_vm.reservation.vehicleType.label)+" ")]),_c('CUIcon',{staticClass:"transition-all transition-duration-300",class:{ 'rotate-n180': !_vm.reservation.collapsed },attrs:{"width":"14px","height":"14px","aria-label":_vm.reservation.collapsed ? 'Expand' : 'Collapse',"color":"additional-blue"}},[_vm._v(" keyboard_arrow_down ")])],1)]),_c('div',{staticClass:"background-bg-gray-1 d-flex align-center justify-space-between padding-x-2 border-x-1 border-b-1 border-t-0 border-solid border-gray-border-light transition-all transition-duration-300",class:{ 'h-24': !_vm.reservation.collapsed, 'h-0': _vm.reservation.collapsed }},[(!_vm.reservation.collapsed)?[_c('p',{staticClass:"font-12 font-bold text-bg-gray-6"},[_vm._v("RES. ID")]),_c('div',{staticClass:"d-flex justify-space-around"},[_c('CUIcon',{attrs:{"aria-label":"Vehicle","width":"16px","color":"bg-gray-6"}},[_vm._v(" bus ")])],1)]:_vm._e()],2)]):_c('div',{staticClass:"h-full d-inline-table",class:{
    'border-white': _vm.reservation.collapsed,
    'border-t-0 border-b-1 border-x-1 border-solid border-gray-border-light':
      !_vm.reservation.collapsed,
  },style:({
    height: !_vm.reservation.collapsed ? '32px' : '0px',
    backgroundColor: _vm.isBlockWithinSelectedRange
      ? _vm.colors['primary-10-opaque']
      : '',
  })},[_c('div',{staticClass:"d-flex w-full h-full align-center justify-space-between flex-wrap padding-x-2",style:({ gap: '2px' })},[(!_vm.isCollapsed)?[_c('p',{staticClass:"font-12 font-bold text-secondary max-w-92 overflow-hidden white-space-nowrap text-overflow-ellipsis cursor-pointer",on:{"click":_vm.handleClickReservation}},[_vm._v(" "+_vm._s(_vm.reservation.managedId || '--')+" ")]),_c('div',{staticClass:"d-flex justify-center min-w-16"},[_c('p',{staticClass:"font-12 font-bold text-text-gray-1"},[_vm._v(" "+_vm._s(_vm.reservation.requiredVehicles)+" ")])])]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }