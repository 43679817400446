import { VehicleType } from '@/models/dto'
import {
  TimelineScaleOptions,
  TimelineScaleOptionsValues,
  TimelineScaleOptionZeroOffset,
  TimelineScaleOptionDays,
  TimelineScaleOptionsType,
} from '@/models/dto/AssignmentsGrid'
import {
  AvailabilityBlock,
  AvailabilityPanelGetResponse,
} from '@/models/dto/Availability'
import colors from '@/scss/_colors-export.scss'
import availability from '@/services/availability'
import type from '@/services/type'
import dayjs, { Dayjs } from 'dayjs'

export const getAvailabilityPanelData = async (
  startDate: string,
  endDate: string
): Promise<{
  response: AvailabilityPanelGetResponse
  vehicleTypes: VehicleType[]
  fleet: Record<string, number>
}> => {
  const [panelData, vt] = await Promise.all([
    availability.getPanelData(
      dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss')
    ),
    type.vehicleTypeTableView({ pageSize: -1 }),
  ])

  return {
    response: panelData.data,
    vehicleTypes: vt.data.resultList,
    fleet: panelData.data.fleetSize,
  }
}

export const formatAvailabilityPanelData = (
  startDate: string,
  endDate: string,
  selectStartDate: string,
  selectEndDate: string,
  scale: string,
  data: AvailabilityPanelGetResponse,
  vehicleTypes: VehicleType[],
  existingData: AvailabilityBlock[] = null
): AvailabilityBlock[] => {
  let daysShownInScale = 0
  switch (scale) {
    case TimelineScaleOptions.HALFDAY:
      daysShownInScale = TimelineScaleOptionDays.HALFDAY
      break
    case TimelineScaleOptions.DAY:
      daysShownInScale = TimelineScaleOptionDays.DAY
      break
    case TimelineScaleOptions.HALFWEEK:
      daysShownInScale = TimelineScaleOptionDays.HALFWEEK
      break
    case TimelineScaleOptions.WEEK:
      daysShownInScale = TimelineScaleOptionDays.WEEK
      break
  }

  const dataForTimelineCalendar = Object.keys(data.fleetSize)
    .reverse()
    .filter((key) => key !== 'unassigned')
    .reduce((arr, vehicleType) => {
      const reservationsByType = (data.reservations[vehicleType] || [])
        .filter((block) =>
          filterBlockDatesByDaysShown(block, startDate, daysShownInScale)
        )
        .map((r) => {
          if (dayjs(r.pickupDate).isAfter(r.dropoffDate)) {
            const start = r.pickupDate
            r.pickupDate = r.dropoffDate
            r.dropoffDate = start
          }
          const [start, end] = getStartAndEndOffsetFromDates(
            dayjs(selectStartDate).format('YYYY-MM-DDTHH:mm:ss'),
            scale,
            r.pickupDate,
            r.dropoffDate,
            true
          )
          return {
            ...r,
            vehicleType: vehicleTypes.find((vt) => vt.key === vehicleType),
            start,
            end,
            collapsed:
              existingData?.find((b) => b.vehicleType.key === vehicleType)
                ?.collapsed ?? true,
          }
        })

      arr.push(
        new AvailabilityBlock({
          availableVehicles: !data.fleetSize[vehicleType]
            ? 0
            : Math.max(
                data.fleetSize[vehicleType] -
                  reservationsByType
                    .filter((r) =>
                      filterBlockDatesByTimeFrame(
                        r,
                        selectStartDate,
                        selectEndDate
                      )
                    )
                    .reduce((sum, r) => sum + r.requiredVehicles, 0),
                0
              ),
          totalVehicles: data.fleetSize[vehicleType] || 0,
          vehicleType: vehicleTypes.find((vt) => vt.key === vehicleType),
          collapsed:
            existingData?.find((b) => b.vehicleType.key === vehicleType)
              ?.collapsed ?? true,
        })
      )
      arr.push(...reservationsByType)
      return arr
    }, [])

  return dataForTimelineCalendar
}

const filterBlockDatesByDaysShown = (
  block: AvailabilityBlock,
  startDate: string,
  daysShownInScale: number
): boolean => {
  return (
    dayjs(block.pickupDate).isBetween(
      startDate,
      dayjs(startDate).add(daysShownInScale, 'days')
    ) ||
    dayjs(block.dropoffDate).isBetween(
      startDate,
      dayjs(startDate).add(daysShownInScale, 'days')
    ) ||
    (dayjs(block.pickupDate).isBefore(startDate) &&
      dayjs(block.dropoffDate).isAfter(
        dayjs(startDate).add(daysShownInScale, 'days')
      ))
  )
}

const filterBlockDatesByTimeFrame = (
  block: AvailabilityBlock,
  startDate: string,
  endDate: string
): boolean => {
  return (
    dayjs(block.pickupDate).isBetween(startDate, endDate) ||
    dayjs(block.dropoffDate).add(1, 'hour').isBetween(startDate, endDate) ||
    (dayjs(block.pickupDate).isBefore(startDate) &&
      dayjs(block.dropoffDate).add(1, 'hour').isAfter(endDate))
  )
}

export const getStartAndEndOffsetFromDates = (
  date: string | Dayjs,
  scale: string,
  startDate: string | Dayjs,
  endDate: string | Dayjs,
  isResBlock = false
): number[] => {
  if (!startDate || !endDate) {
    return [null, null]
  }
  let minutesFromEnd = 0
  if (isResBlock) {
    minutesFromEnd = dayjs(endDate).diff(date, 'minutes')
  } else {
    minutesFromEnd = dayjs(endDate).diff(startDate, 'minutes')
  }
  const minutesFromStart = dayjs(startDate).diff(date, 'minutes')

  let conversionScale = 0
  let zeroOffset = 0
  switch (scale) {
    case TimelineScaleOptions.HALFDAY:
      conversionScale = TimelineScaleOptionsValues.HALFDAY
      zeroOffset = TimelineScaleOptionZeroOffset.HALFDAY
      break
    case TimelineScaleOptions.DAY:
      conversionScale = TimelineScaleOptionsValues.DAY
      zeroOffset = TimelineScaleOptionZeroOffset.DAY
      break
    case TimelineScaleOptions.HALFWEEK:
      conversionScale = TimelineScaleOptionsValues.HALFWEEK
      zeroOffset = TimelineScaleOptionZeroOffset.HALFWEEK
      break
    case TimelineScaleOptions.WEEK:
      conversionScale = TimelineScaleOptionsValues.WEEK
      zeroOffset = TimelineScaleOptionZeroOffset.WEEK
      break
  }

  return [
    isResBlock
      ? (minutesFromStart * conversionScale) / 24 / 60 + zeroOffset
      : zeroOffset,
    (minutesFromEnd * conversionScale) / 24 / 60 + zeroOffset,
  ]
}

export const getBlockColor = (
  data: AvailabilityBlock[],
  reservation: AvailabilityBlock
): { bg: string; border: string } => {
  if (!reservation?.assignments) {
    return { bg: colors['bg-gray-5'], border: colors['bg-gray-5'] }
  }

  const reservations = data.filter(
    (r) => r.reservationId === reservation.reservationId
  )

  const numUnassigned =
    reservation.requiredVehicles -
    reservations.reduce((sum, r) => sum + r.assignments.length, 0) +
    reservation.requiredDrivers -
    reservations.flatMap((r) => r.assignments).flatMap((a) => a.drivers).length

  const numDrafts =
    reservations.flatMap((r) => r.assignments).filter((a) => !a.isConfirmed)
      .length +
    reservations
      .flatMap((r) => r.assignments.flatMap((a) => a.drivers))
      .filter((d) => !d.isConfirmed).length

  if (numUnassigned > 0) {
    return { bg: colors['primary-10'], border: colors['primary'] }
  } else if (numDrafts > 0) {
    return { bg: colors['bg-gray-5'], border: colors['bg-gray-5'] }
  }
  return { bg: colors['secondary'], border: colors['secondary'] }
}

export const getEndDateFromStartDateAndScale = (
  startDate: dayjs.Dayjs,
  scale: TimelineScaleOptionsType
): dayjs.Dayjs => {
  switch (scale) {
    case TimelineScaleOptions.HALFDAY:
      return startDate.add(12, 'hour')
    case TimelineScaleOptions.DAY:
      return startDate.add(1, 'day')
    case TimelineScaleOptions.HALFWEEK:
      return startDate.add(3, 'day')
    case TimelineScaleOptions.WEEK:
      return startDate.add(6, 'day')
    default:
      return startDate
  }
}

