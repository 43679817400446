
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AvailabilityBlock } from '@/models/dto/Availability'
import { pluralize } from '@/utils/string'
import {
TimelineScaleOptionDays,
  TimelineScaleOptions,
  TimelineScaleOptionsValues,
  TimelineScaleOptionZeroOffset,
} from '@/models/dto/AssignmentsGrid'
import sidebar from '@/store/modules/sidebar'
import ReservationDetailPanel from './ReservationDetailPanel.vue'
import assignment from '@/store/modules/assignment'
import { openInNewTab } from '@/utils/router'
import { Dayjs } from 'dayjs'

@Component
export default class AvailabilityCalendarRow extends Vue {
  @Prop({ required: true }) row: AvailabilityBlock
  @Prop({ required: true }) color: { bg: string; border: string }
  @Prop({ required: true }) scale: string
  @Prop({ required: true }) startDate: Dayjs
  @Prop({ required: false, default: null }) selected: {
    start: number
    end: number
  }
  @Prop({ required: false, default: null }) selectedFlags: {
    start: number
    end: number
  }

  pluralize = pluralize

  get blockHoverText(): string {
    return (this.row?.assignments || []).reduce((str, assignment) => {
      const vehicle = `<span class="font-bold">${assignment.vehicleName}</span>`
      const drivers = assignment.drivers.map((d) => d.driverName).join(', ')

      str = `${str}<p>${vehicle} - ${drivers}</p>`
      return str
    }, `<p>${this.row.customerName}</p>`)
  }

  maxWidth(): number {
    switch (this.scale) {
      case TimelineScaleOptions.HALFDAY:
        return TimelineScaleOptionDays.HALFDAY * TimelineScaleOptionsValues.HALFDAY
      case TimelineScaleOptions.DAY:
        return TimelineScaleOptionDays.DAY * TimelineScaleOptionsValues.DAY
      case TimelineScaleOptions.HALFWEEK:
        return TimelineScaleOptionDays.HALFWEEK * TimelineScaleOptionsValues.HALFWEEK
      case TimelineScaleOptions.WEEK:
        return TimelineScaleOptionDays.WEEK * TimelineScaleOptionsValues.WEEK
      default:
        return TimelineScaleOptionDays.DAY * TimelineScaleOptionsValues.DAY
    }
  }

  get rowWidth(): number {
    if (this.maxWidth() < this.row.end) {
      return this.maxWidth() - (this.row.start < 0 ? -3 : this.row.start)
    }
    return this.row.end - (this.row.start < 0 ? -3 : this.row.start)
  }

  get bufferTimeWidth(): number {
    switch (this.scale) {
      case TimelineScaleOptions.DAY:
        return TimelineScaleOptionsValues.DAY / 24
      case TimelineScaleOptions.HALFWEEK:
        return TimelineScaleOptionsValues.HALFWEEK / 24
      case TimelineScaleOptions.WEEK:
        return TimelineScaleOptionsValues.WEEK / 24
      default:
        return TimelineScaleOptionsValues.HALFDAY / 24
    }
  }

  get dayWidth(): number {
    switch (this.scale) {
      case TimelineScaleOptions.DAY:
        return TimelineScaleOptionsValues.DAY
      case TimelineScaleOptions.HALFWEEK:
        return TimelineScaleOptionsValues.HALFWEEK
      case TimelineScaleOptions.WEEK:
        return TimelineScaleOptionsValues.WEEK
      default:
        return TimelineScaleOptionsValues.HALFDAY
    }
  }

  get initialOffset(): number {
    switch (this.scale) {
      case TimelineScaleOptions.DAY:
        return TimelineScaleOptionZeroOffset.DAY
      case TimelineScaleOptions.HALFWEEK:
        return TimelineScaleOptionZeroOffset.HALFWEEK
      case TimelineScaleOptions.WEEK:
        return TimelineScaleOptionZeroOffset.WEEK
      default:
        return TimelineScaleOptionZeroOffset.HALFDAY
    }
  }

  get vehicleAvailableCountOffset(): number {
    return (
      this.selectedFlags.start +
      (this.selectedFlags.end - this.selectedFlags.start) / 2
    )
  }

  get vehicleAvailableCountColor(): string {
    if (this.row.availableVehicles === 0) {
      return 'bg-gray-4'
    } else if (this.selected) {
      return 'primary'
    }
    return 'additional-blue'
  }

  handleOpenDetailSidebar(): void {
    sidebar.push({
      component: ReservationDetailPanel,
      props: {
        reservationId: this.row?.reservationId,
        managedId: this.row?.managedId,
        popOnClose: true,
      },
      width: 764,
      wide: true,
      persist: true,
    })
  }

  handleGoToGridView(): void {
    assignment.setPreserveDataOnInit(true)
    assignment.setCurrentDate(this.startDate)
    assignment.setScale(this.scale)

    openInNewTab({ name: 'assignments' })
  }
}
