
import Vue from 'vue'
import Component from 'vue-class-component'
import AvailabilityMonthCalendar from '@/components/AvailabilityMonthCalendar.vue'
import AvailabilityHeader from '@/components/AvailabilityHeader.vue'
import { DispatchBlock } from '@/models/dto/Dispatch'
import auth from '@/store/modules/auth'
import dayjs from 'dayjs'

@Component({
  components: {
    AvailabilityHeader,
    AvailabilityMonthCalendar,
  },
  metaInfo: {
    title: 'Availability',
  },
})
export default class AvailabilityCalendar extends Vue {
  currentDate: dayjs.Dayjs = dayjs()
  reservations: Record<string, DispatchBlock> = {}

  get getCurrentDate(): dayjs.Dayjs {
    return this.currentDate
  }

  get getCurrentDateAsJSDate(): Date {
    return this.currentDate.toDate()
  }

  get reservationsInCorrectTimeZone(): DispatchBlock[] {
    return Object.values(this.reservations).map((reservation) => {
      return {
        ...reservation,
        startDate: dayjs(reservation.startDate)
          .tz(auth.getUserTimeZone)
          .format('YYYY-MM-DD hh:mm a'),
        endDate: dayjs(reservation.endDate)
          .tz(auth.getUserTimeZone)
          .format('YYYY-MM-DD hh:mm a'),
      }
    })
  }

  setCurrentDate(): void {
    this.currentDate = dayjs()
  }

  incrementDate(): void {
    this.currentDate = this.currentDate.add(1, 'month')
  }

  decrementDate(): void {
    this.currentDate = this.currentDate.subtract(1, 'month')
  }

  get reservationsToDisplay(): DispatchBlock[] {
    return [...this.reservationsInCorrectTimeZone]
  }
}
