<template>
  <div class="padding-a-3 d-flex justify-end h-full flex-column">
    <div>
      <div class="d-flex align-center justify-start">
        <div class="d-flex align-center justify-start">
          <CUIcon height="18px" class="text-gray-text-dark w-18">Bus</CUIcon>
        </div>
        <CUSkeletonLoader
          height="10px"
          width="35px"
          class="border-radius-10 margin-l-2"
        />
      </div>
      <CUSkeletonLoader height="5px" width="100%" class="border-radius-10" />
    </div>
    <div>
      <div class="d-flex align-center justify-start margin-t-1">
        <div class="d-flex align-center justify-start">
          <CUIcon height="18px" class="text-gray-text-dark w-18">Driver</CUIcon>
        </div>
        <CUSkeletonLoader
          height="10px"
          width="35px"
          class="border-radius-10 margin-l-2"
        />
      </div>
      <CUSkeletonLoader height="5px" width="100%" class="border-radius-10" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvailabilityCalendarItemLoader',
}
</script>
