var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"margin-b-3"},[_c('div',{staticClass:"padding-a-0 margin-x-0 max-w-full"},[_c('div',{staticClass:"d-flex align-center padding-a-0 margin-a-0 flex-nowrap"},[_vm._m(0),_c('div',{staticClass:"d-flex align-center margin-t-3",style:({ gap: '8px' })},[_c('div',[_c('v-progress-linear',{staticClass:"border-radius-5",style:({ width: '60px' }),attrs:{"value":"100","buffer-value":"20","background-color":"grey","background-opacity":"0.2","color":"secondary"}}),_c('span',{staticClass:"font-12 leading-6",style:({
              fontFamily: 'Nunito, sans-serif',
            })},[_vm._v(" % booked ")])],1),_c('CUTooltip',{attrs:{"tooltip-text":"Percentage booked is a representation of your vehicle and driver utilization based on 24 hours of available trip time per day. It is calculated as (<b>reservation hours</b> multiplied by <b>required vehicle or driver count</b> per trip) <b>divided by (total vehicles or drivers</b> in the fleet on that date <b>multiplied by 24 hours).</b>","max-width":"500px","right":""}},[_c('CUIcon',{staticClass:"cursor-pointer",attrs:{"width":"20px","color":"bg-gray-4","hide-title":""}},[_vm._v(" info ")])],1)],1),_c('v-spacer'),_c('div',{staticClass:"shrink padding-a-1 margin-r-2"},[_c('CUButton',{staticClass:"border-solid border-green border-radius-8 background-transparent",attrs:{"outlined":"","width":"32","height":"32","padding-x":"0"},on:{"click":function($event){return _vm.$emit('decrement-date', _vm.date)}}},[_c('CUIcon',{attrs:{"aria-label":"Previous","color":"green"}},[_vm._v(" keyboard_arrow_left ")])],1)],1),_c('div',{staticClass:"shrink padding-a-1 margin-r-4"},[_c('CUButton',{staticClass:"border-solid border-green border-radius-8 background-transparent",attrs:{"outlined":"","width":"32","height":"32","padding-x":"0"},on:{"click":function($event){return _vm.$emit('increment-date', _vm.date)}}},[_c('CUIcon',{attrs:{"aria-label":"Next","color":"green"}},[_vm._v(" keyboard_arrow_right ")])],1)],1),_c('div',{staticClass:"d-flex justify-center align-center margin-r-4 shrink padding-a-1 text-no-wrap font-bold font-18 w-120"},[_c('span',[_vm._v(" "+_vm._s(_vm.headerString)+" ")])]),_c('div',{staticClass:"shrink padding-l-1 padding-y-1 margin-r-0 padding-r-0"},[_c('CUButton',{staticClass:"border-solid border-radius-8 background-transparent padding-a-2",class:{
            'border-orange': !_vm.isTodayButtonDisabled,
            'grey--text': _vm.isTodayButtonDisabled,
          },attrs:{"disabled":_vm.isTodayButtonDisabled,"outlined":"","min-width":"130","height":"40","padding-x":"0"},on:{"click":function($event){return _vm.$emit('set-current-date', _vm.date)}}},[_vm._v(" Today ")])],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"shrink padding-a-1 margin-r-9"},[_c('span',{staticClass:"font-bold leading-40 leading-none font-24"},[_vm._v(" Availability ")])])
}]

export { render, staticRenderFns }