var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"background-bg-gray-1 border-radius-20"},[_c('CalendarView',{ref:"calendar",staticClass:"border-3 border-radius-20 box-shadow-medium vue-availability-calendar--month",attrs:{"show-date":_vm.displayDate.toDate(),"weekday-name-format":"long"},scopedSlots:_vm._u([{key:"dayHeader",fn:function({ label }){return [_c('div',{staticClass:"margin-b-2 h-40 border-3 first-of-type:border-radius-bottom-left-20 last-of-type:border-radius-bottom-right-20 d-flex border-gray-text-dark background-bg-gray-2 w-full align-center justify-space-around font-14 text-text-gray-3 font-semibold"},[_vm._v(" "+_vm._s(label)+" ")])]}},{key:"dayContent",fn:function({ day }){return [_c('div',{staticClass:"d-flex flex-column-reverse justify-end margin-a-1 border-radius-20 margin-6 border-1 border-solid border-gray-2 margin-l-1",class:{
          'border-bg-gray-2 background-bg-gray text-text-gray-2':
            _vm.handleDayCalendar(day) === _vm.calendarClasses.differentMonth ||
            _vm.handleDayCalendar(day) === _vm.calendarClasses.futureDays,
          'border-bg-gray-4 text-text-gray-1 background-bg-white-1':
            _vm.handleDayCalendar(day) === _vm.calendarClasses.pastedDay,
          ' background-white text-text-gray-2 border-gray-text-mid':
            _vm.handleDayCalendar(day) === _vm.calendarClasses.availableDays,
          'border-black border-1': _vm.dayjs(day).isSame(_vm.dayjs(), 'day'),
        },style:({ width: '100%' })},[_c('div',{staticClass:"d-flex justify-end position-absolute margin-t-1 padding-r-4",style:({ width: '100%' })},[_c('button',{staticClass:"padding-a-4 font-14 d-flex justify-center align-center w-28 h-28 border-radius-20 margin-r-1 margin-t-1 font-14 leading-20",class:{
              'text-text-gray-2':
                _vm.dayjs(day).format('MM') !== _vm.displayDate.format('MM') ||
                _vm.dayjs(day).isBefore(_vm.dayjs().add(-1, 'day')),
              'background-black text-white': _vm.dayjs(day).isSame(
                _vm.dayjs(),
                'day'
              ),
            },style:({ fontFamily: 'Nunito, sans-serif' }),on:{"click":function($event){return _vm.$emit('set-current-date', day)}}},[_vm._v(" "+_vm._s(_vm.dayjs(day).format('D'))+" ")])]),(_vm.loaded)?_c('AvailabilityCalendarItem',{attrs:{"holiday":_vm.getHolidaysPerData(_vm.dayjs(day).format('YYYY-MM-DD')),"availability":_vm.getBookedDayInformation(day),"date":_vm.displayDate}}):_c('AvailabilityCalendarItemLoader')],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }