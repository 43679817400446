import { ApiResult, Reservation, Vehicle, VehicleType } from '.'

export interface AvailabilityGetRequest {
  startPeriod: string
  endPeriod: string
}

export interface AvailabilityGetResponse extends ApiResult {
  reservations: Reservation[]
}

export interface AvailabilityPanelGetResponse extends ApiResult {
  startPeriod: string
  endPeriod: string
  fleetSize: Record<string, number>
  reservations: Record<string, AvailabilityBlock[]>
}

export interface AvailabilityBlock {
  reservationId: number
  managedId: string
  pickupDate: string
  dropoffDate: string
  customerName: string
  assignments: AvailabilityBlockAssignments[]
  requiredVehicles: number
  requiredDrivers: number
  totalVehicles?: number
  availableVehicles?: number
  vehicleType?: VehicleType
  collapsed?: boolean
  isVehicleType?: boolean
  start?: number
  end?: number
}

export interface AvailabilityResponse extends ApiResult {
  [x: string]: any
  startPeriod: string | Date
  endPeriod: string | Date
  reservationHours: number
  soldOut: boolean
  totalDrivers: number
  totalVehicles: number
  utilizedDriverPercentage: number
  utilizedVehiclePercentage: number
  shortDate?: string
}

export interface VehicleBlockItem {
  blocks: AvailabilityBlock[]
  vehicle: Vehicle | string
  startingHeight?: number
}

// eslint-disable-next-line no-redeclare
export class AvailabilityBlock implements AvailabilityBlock {
  constructor(availabilityBlock?: Partial<AvailabilityBlock>) {
    Object.assign(this, availabilityBlock)
  }
}

export interface AvailabilityBlockAssignments {
  vehicleId: number
  vehicleName: string
  isConfirmed: boolean
  drivers: AvailabilityBlockDrivers[]
}

export interface AvailabilityBlockDrivers {
  driverId: number
  driverName: string
  isConfirmed: boolean
}

export interface Holiday {
  date: string
  localName: string
  name: string
  countryCode: string
  fixed: boolean
  global: boolean
  counties: [] | null
  launchYear: number | null
  types: [string]
}

export interface SoldOut {
  date: string
  soldOut: boolean
}

export type SoldOutIconsType = 'plus' | 'confirm' | 'minus'
export const SoldOutIcons: Record<string, SoldOutIconsType> = {
  plus: 'plus',
  confirm: 'confirm',
  minus: 'minus',
}
