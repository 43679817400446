
import { TimelineScaleOptions } from '@/models/dto/AssignmentsGrid'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import dayjs, { Dayjs } from 'dayjs'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class AvailabilitySidebarHeader extends Vue {
  @Prop({ required: true }) startDatetime: Dayjs
  @Prop({ required: true }) endDatetime: Dayjs
  @Prop({ required: true }) scale: string
  @Prop({ type: Boolean, default: false }) isSelectMode: boolean

  sidebar = sidebar

  passengers = 'All'
  startDate = null
  startTime = null
  endDate = null
  endTime = null
  filtersHaveChanged = false

  @Watch('startDatetime', { immediate: true })
  @Watch('endDatetime')
  onDatetimeChange(): void {
    this.startDate = this.startDatetime.format('YYYY-MM-DD')
    this.startTime = this.startDatetime.format('HH:mm')
    this.endDate = this.endDatetime.format('YYYY-MM-DD')
    this.endTime = this.endDatetime.format('HH:mm')
  }

  get computedScaleOptions(): string[] {
    const hours = this.endDatetime.diff(this.startDatetime, 'hours')
    const options = []

    if (hours <= 12) {
      options.push(TimelineScaleOptions.HALFDAY)
    }
    if (hours <= 24) {
      options.push(TimelineScaleOptions.DAY)
    }
    if (hours <= 24 * 3) {
      options.push(TimelineScaleOptions.HALFWEEK)
    }
    options.push(TimelineScaleOptions.WEEK)

    return options
  }

  handleScaleChange(scale: string): void {
    this.filtersHaveChanged = true
    this.$emit('update:scale', scale)
  }

  handleChangeStartDate(date: string): void {
    this.filtersHaveChanged = true

    if (dayjs(date).isValid()) {
      this.$emit('update:startDate', `${date}T${this.startTime}:00.000`)
    }
  }

  handleChangeStartTime(time: string): void {
    this.filtersHaveChanged = true
    this.$emit('update:startTime', `${this.startDate}T${time}:00.000`)
  }

  handleChangeEndDate(date: string): void {
    if (
      this.isOutOfRange(
        `${date}T${this.endTime}:59.999`,
        `${this.startDate}T${this.startTime}:00.000`
      )
    ) {
      return
    }
    this.filtersHaveChanged = true
    this.$emit('update:endDate', `${date}T${this.endTime}:59.999`)
  }

  handleChangeEndTime(time: string): void {
    this.filtersHaveChanged = true
    this.$emit('update:endTime', `${this.endDate}T${time}:59.999`)
  }

  isOutOfRange(a: string, b: string): boolean {
    if (Math.abs(dayjs(a).diff(b, 'hours')) > 6 * 24) {
      EventBus.$emit(
        'snackbar:error',
        'Please select a time range no greater than 6 days.'
      )
      return true
    }
    return false
  }
}
