
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AvailabilityBlock } from '@/models/dto/Availability'
import ReservationDetailPanel from './ReservationDetailPanel.vue'
import sidebar from '@/store/modules/sidebar'
import { Dayjs } from 'dayjs'
import colors from '@/scss/_colors-export.scss'

@Component
export default class AvailabilityReservationCard extends Vue {
  @Prop({ required: true }) reservation: AvailabilityBlock
  @Prop({ required: false }) startDate?: Dayjs
  @Prop({ required: false }) endDate?: Dayjs

  colors = colors

  isCollapsed = false

  // Delay showing the row's content when expanding
  @Watch('reservation.collapsed', { deep: true, immediate: true })
  handleVehicleUpdate(newVal: boolean, oldVal: boolean): void {
    if (oldVal === true && newVal === false) {
      setTimeout(() => {
        this.isCollapsed = false
      }, 300)
    } else {
      this.isCollapsed = newVal
    }
  }

  get isBlockWithinSelectedRange(): boolean {
    return (
      this.startDate &&
      this.endDate &&
      this.endDate.isSameOrAfter(this.reservation.pickupDate) &&
      this.startDate.isSameOrBefore(this.reservation.dropoffDate)
    )
  }

  handleClickReservation(): void {
    sidebar.push({
      component: ReservationDetailPanel,
      props: {
        reservationId: this.reservation?.reservationId,
        managedId: this.reservation?.managedId,
        popOnClose: true,
      },
      width: 764,
      wide: true,
      persist: true,
    })
  }
}
