
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { CalendarView } from 'vue-simple-calendar'
import dayjs, { Dayjs } from 'dayjs'
import AvailabilityCalendarItem from './AvailabilityCalendarItem.vue'
import AvailabilityCalendarItemLoader from './AvailabilityCalendarItemLoader.vue'
import colors from '@/scss/_colors-export.scss'
import availabilityClient from '@/services/availability'
import { AvailabilityResponse, Holiday } from '@/models/dto/Availability'

@Component({
  components: {
    CalendarView,
    AvailabilityCalendarItem,
    AvailabilityCalendarItemLoader,
    colors,
  },
})
export default class AvailabilityMonthCalendar extends Vue {
  @Prop({ required: true }) display!: Dayjs
  @Prop({ required: true }) js!: Date
  dayjs = dayjs
  loaded = false
  dateJS = this.js
  displayDate = dayjs(this.display)
  availabilityDaysMap: { [key: string]: AvailabilityResponse } = {}
  holidaysMap: { [key: string]: Holiday } = {}

  calendarClasses = {
    availableDays: 'availableDays',
    differentMonth: 'differentMonth',
    pastedDay: 'pastedDay',
    futureDays: 'futureDays',
  }

  @Watch('display')
  decrementDate(): void {
    this.displayDate = dayjs(this.display)
  }

  incrementDate(): void {
    this.displayDate = dayjs(this.display)
  }

  created(): void {
    this.fetchAvailabilityDates()
  }

  getBookedDayInformation(day: string): AvailabilityResponse {
    const formattedDay = dayjs(day).format('YYYY-MM-DD')
    return (
      this.availabilityDaysMap[formattedDay] || {
        ...this.availabilityDaysMap[formattedDay],
        soldOut: false,
        shortDate: formattedDay,
      }
    )
  }

  getHolidaysPerData(day: string): Holiday {
    const formattedDay = dayjs(day).format('YYYY-MM-DD')
    return this.holidaysMap[formattedDay]
  }

  // on update
  @Watch('js')
  async updateDateJS(): Promise<void> {
    this.dateJS = this.js
    this.loaded = false
    await this.fetchAvailabilityDates()
  }

  checkIfRequiresLoad(start: string, end: string): boolean {
    let currentDate = start
    let alreadyLoaded = true
    while (dayjs(currentDate).isBefore(end)) {
      if (this.availabilityDaysMap[dayjs(currentDate).format('YYYY-MM-DD')]) {
        currentDate = dayjs(currentDate)
          .add(1, 'day')
          .format('YYYY-MM-DDTHH:mm:ss')
        continue
      } else {
        alreadyLoaded = false
        break
      }
    }

    return alreadyLoaded
  }

  async fetchAvailabilityDates(): Promise<void> {
    const buffer = 6 // days, so that the entire calendar view is covered
    const firstDayOfMonth = dayjs(this.dateJS)
      .startOf('month')
      .add(-buffer, 'day')
      .format('YYYY-MM-DDTHH:mm:ss')
    const lastDayOfMonth = dayjs(this.dateJS)
      .endOf('month')
      .add(buffer, 'day')
      .format('YYYY-MM-DDTHH:mm:ss')

    if (this.checkIfRequiresLoad(firstDayOfMonth, lastDayOfMonth)) {
      this.loaded = true
      return
    }

    try {
      const availabilityDaysPromise = availabilityClient.getAvailabilityDays({
        startPeriod: firstDayOfMonth,
        endPeriod: lastDayOfMonth,
      })

      const holidaysPromise = availabilityClient.getHolidays(
        dayjs(this.dateJS).format('YYYY')
      )

      const [availabilityDays, holidays] = await Promise.allSettled([
        availabilityDaysPromise,
        holidaysPromise,
      ])

      if (availabilityDays.status === 'fulfilled') {
        for (const availabilityDay of availabilityDays.value.data) {
          const shortDate = dayjs(
            (availabilityDay.startPeriod as string).split('T')[0]
          ).format('YYYY-MM-DD')
          this.availabilityDaysMap[shortDate] = {
            ...availabilityDay,
            shortDate,
          }
        }
      } else {
        console.error(availabilityDays.reason)
      }

      if (holidays.status === 'fulfilled') {
        for (const holiday of holidays.value.data) {
          this.holidaysMap[holiday.date] = holiday
        }
      } else {
        console.error(holidays.reason)
      }

      this.loaded = true
    } catch (error) {
      console.log(error)
    }
  }

  get date(): string {
    return this.displayDate.format('MMMM YYYY')
  }

  handleDayCalendar(date: string): string {
    const itemMonth = dayjs(date)
    const displayedMonth = dayjs(this.displayDate)
    const isSameMonth =
      this.dayjs(date).format('MMMM YYYY') ===
      dayjs(this.displayDate).format('MMMM YYYY')
    if (isSameMonth) {
      const today = this.dayjs().startOf('day')
      const inputDate = this.dayjs(date).startOf('day')
      if (inputDate !== today) {
        if (
          inputDate < today &&
          dayjs().format('MMMM YYYY') === displayedMonth.format('MMMM YYYY')
        ) {
          return this.calendarClasses.pastedDay
        } else {
          return this.calendarClasses.availableDays
        }
      }
    } else if (itemMonth.isBefore(displayedMonth)) {
      return this.calendarClasses.differentMonth
    } else {
      return this.calendarClasses.futureDays
    }
  }
}
