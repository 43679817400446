var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.row.reservationId)?_c('div',{staticClass:"background-additional-blue-2"},[_c('div',{staticClass:"h-32 background-additional-blue-2 padding-y-1"},[_c('div',{staticClass:"position-relative w-116 d-flex align-center justify-center background-white border-1 border-solid border-radius-20 z-6 transition-all transition-duration-300",class:`border-${_vm.vehicleAvailableCountColor}`,style:({
        left: `${_vm.vehicleAvailableCountOffset}px`,
        transform: 'translateX(-50%)',
      })},[_c('p',{staticClass:"font-bold transition-all transition-duration-300",class:`text-${_vm.vehicleAvailableCountColor}`,style:({ margin: '1px 0 -1px 0' })},[_vm._v(" "+_vm._s(_vm.row.availableVehicles)+" available ")])])]),_c('div',{staticClass:"background-bg-gray-1 d-flex align-center justify-space-between padding-x-2 border-x-0 border-b-1 border-t-0 border-solid border-gray-border-light transition-all transition-duration-300",class:{ 'h-24': !_vm.row.collapsed, 'h-0': _vm.row.collapsed }},[(!_vm.row.collapsed)?_c('p',{staticClass:"font-12 font-bold text-center text-bg-gray-6",style:({ marginLeft: `${_vm.initialOffset}px` })},[_vm._v(" For the selected time, you have "+_vm._s(_vm.row.availableVehicles)+" "+_vm._s(_vm.pluralize(_vm.row.availableVehicles, _vm.row.vehicleType.label))+" "),_c('span',{staticClass:"cursor-pointer text-secondary",on:{"click":_vm.handleGoToGridView}},[_vm._v(" out of "+_vm._s(_vm.row.totalVehicles)+" ")]),_vm._v(" available ")]):_vm._e()])]):_c('div',{class:{
    'border-white': !_vm.row.isVehicleType && _vm.row.collapsed,
    'border-b-1 border-x-0 border-t-0 border-solid border-gray-border-light':
      !_vm.row.collapsed,
  },style:({ height: !_vm.row.collapsed ? '32px' : '0px', overflowX: 'hidden' })},[(!_vm.row.collapsed)?_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"position-absolute d-flex top-2 cursor-pointer",style:({ left: `${_vm.row.start < 0 ? -3 : _vm.row.start}px` }),on:{"click":_vm.handleOpenDetailSidebar}},[_c('CUTooltip',{attrs:{"tooltip-text":_vm.blockHoverText,"top":""}},[_c('div',{staticClass:"w-full padding-x-1 border-1 border-solid transition-background transition-duration-300",class:{
            'border-radius-top-left-4 border-radius-bottom-left-4':
              _vm.row.start !== 0,
          },style:({
            width: `${_vm.rowWidth}px`,
            height: '26px',
            backgroundColor: _vm.color.bg,
            borderColor: _vm.color.border,
          })})]),_c('div',{staticClass:"background-bg-gray-1 border-1 border-solid border-bg-gray-5 border-radius-top-right-4 border-radius-bottom-right-4",style:({
          height: '26px',
          width: `${_vm.bufferTimeWidth}px`,
        })})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }