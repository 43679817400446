
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUTimelineCalendarTimeline from '@/components/CUTimelineCalendarTimeline.vue'
import CUTimelineLeftColumnLoader from '@/components/CUTimelineLeftColumnLoader.vue'
import CUTimelineLegend from '@/components/CUTimelineLegend.vue'
import { TimelineScaleOptions } from '@/models/dto/AssignmentsGrid'
import dayjs, { Dayjs } from 'dayjs'

/*
 * Description:
 *  A generic components that displays a calendar with grouped data on the y-axis and a timeline on the x-axis
 *
 * Props:
 *  data - an array of the data displayed on the y-axis
 *  loading - whether the data is loading
 *  height - the height of the calendar
 *  scale - the scale of the timeline
 *  startDate - the start date of the timeline
 *  selected - the selected data's time range
 *  legendKeys - the keys displayed in the legend
 *
 * Slots:
 *  leftColumnHeader - displayed in the top left corner of the calendar, does not scroll
 *  leftColumnRow - displayed in a row on the left of the calendar, scrolls vertically only
 *  dataRow - displayed in a row to the right of the leftColumnRow, scrolls in both directions
 *  legend - displayed at the bottom of the calendar
 */

@Component({
  components: {
    CUTimelineCalendarTimeline,
    CUTimelineLeftColumnLoader,
    CUTimelineLegend,
  },
})
export default class CUTimelineCalendar extends Vue {
  @Prop({ required: true }) data: unknown[]
  @Prop({ type: Boolean, default: false }) loading: boolean
  @Prop({ required: false, default: '100%' }) height: string
  @Prop({ required: false, default: TimelineScaleOptions.DAY }) scale: string
  @Prop({ required: false, default: dayjs() }) startDate: Dayjs
  @Prop({ required: false, default: null }) legendKeys: string[]
  @Prop({ required: false, default: null }) selected: {
    start: number
    end: number
  }
  @Prop({ required: false, default: null }) selectedStartDate: Dayjs
}
