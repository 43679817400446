
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DispatchViewOptions } from '@/utils/dispatch'
import { toTitle } from '@/utils/string'
import dayjs, { Dayjs } from 'dayjs'

@Component({})
export default class AvailabilityHeader extends Vue {
  @Prop({ required: true }) date!: Dayjs
  dayjs = dayjs

  get headerString(): string {
    if (this.date) {
      return this.date.format('MMMM YYYY')
    } else {
      return ''
    }
  }

  get menuItems(): unknown {
    return Object.values(DispatchViewOptions).map((view) => {
      return {
        label: toTitle(view),
        key: view,
      }
    })
  }

  get isTodayButtonDisabled(): boolean {
    return this.dayjs().isSame(this.date, 'day')
  }
}
